import firebase from 'firebase';
import { Formik } from 'formik';
import { graphql, Link, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Container, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { defaultLanguage } from '../../prismic-configuration';
import PersonalDataForm from '../components/Form-components/PersonalDataForm/BasicPersonalDataForm-component';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { linkResolver } from '../utils/LinkResolver';

const isBrowser = typeof window !== 'undefined';

const McmReviewForm = ({ location, data }) => {
  const [status, setStatus] = useState('pending');
  const [message, setMessage] = useState('');
  const [showFinalSpinner, setShowFinalSpinner] = useState(true);
  const [firstFormVisibility, setFirstFormVisibility] = useState('');
  const [fullPortfolioVisibility, setFullPortfolioVisibility] = useState('');
  const [secondFormVisibility, setSecondFormVisibility] = useState('d-none');
  const [titleVisibility, setTitleVisibility] = useState('');
  const [finalInfo, setFinalInfo] = useState('d-none');
  const [applicantID, setApplicantID] = useState('loading...');
  const [allUsers, setAllUsers] = useState([]);
  const [userUnderReview, setUserUnderReview] = useState([]);
  const [currentMCMData, setCurrentMCMData] = useState([]);
  const [currentReviewingProcess, setCurrentReviewingProcess] = useState([]);
  const [formFieldEntries, setFormFieldEntries] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const params = queryString.parse(location.search);

    if (isBrowser && isMounted) {
      try {
        if (params.userId) {
          setApplicantID(params.userId);
        } else {
          setStatus('failed');
          setMessage('Missing needed information.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        //fetch if exist reviewingCollection data
        firebase
          .firestore()
          .collection('reviewingCollection')
          .onSnapshot(snapshot => {
            const reviewingProcesses = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            const currentApplicantsId = params.userId;
            const currentMCMUid = firebase.auth().currentUser?.uid;
            const reviewingProcessData = reviewingProcesses.filter(
              reviewingProcess =>
                reviewingProcess.mixedProcessId === currentMCMUid + currentApplicantsId,
            );
            setCurrentReviewingProcess(reviewingProcessData[0]);
            handleCloseFinalSpinner();
          });
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            const currentApplicantsId = params.userId;

            const currentMCMUid = firebase.auth().currentUser?.uid;

            // Now we set users equal to users we read from the Firestore
            const userUnderReview = listUsers.filter(
              userFromList => userFromList.id === currentApplicantsId,
            );
            const currentMCMData = listUsers.filter(
              userFromList => userFromList.uid === currentMCMUid,
            );
            setAllUsers(listUsers);
            setCurrentMCMData(currentMCMData[0]);
            setUserUnderReview(userUnderReview[0]);
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [location.search]);

  //fetch if exist Firebase firestore(database) data
  useLayoutEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));

            setAllUsers(listUsers);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [applicantID]);
  // setFormFieldEntries
  useEffect(() => {
    if (allUsers.length !== 0) {
      const usersPersonalData = allUsers.filter(userFromList => userFromList.id === applicantID)[0];

      const entries = usersPersonalData ? Object.entries(usersPersonalData) : {};
      setFormFieldEntries(entries);
      // console.log('====>>>1', entries)
    }
  }, [allUsers]);

  useEffect(() => {
    const addRevProcessID = async () => {
      if (userUnderReview?.mcm1 === currentMCMData.id) {
        try {
          !userUnderReview?.mcm1RevProcess &&
            (await firestore.collection('usersCollection').doc(userUnderReview.id).update({
              mcm1RevProcess: currentReviewingProcess?.id,
            }));
        } catch (err) {
          console.log(err);
        }
      }
      if (userUnderReview?.mcm2 === currentMCMData.id) {
        try {
          !userUnderReview?.mcm2RevProcess &&
            (await firestore.collection('usersCollection').doc(userUnderReview.id).update({
              mcm2RevProcess: currentReviewingProcess?.id,
            }));
        } catch (err) {
          console.log(err);
        }
      }
      if (userUnderReview?.mcm3 === currentMCMData.id) {
        try {
          !userUnderReview?.mcm3RevProcess &&
            (await firestore.collection('usersCollection').doc(userUnderReview.id).update({
              mcm3RevProcess: currentReviewingProcess?.id,
            }));
        } catch (err) {
          console.log(err);
        }
      }
    };
    !!currentReviewingProcess && addRevProcessID();
    return () => {};
  }, [currentReviewingProcess]);

  if (!data) return null;
  // console.log(userUnderReview)
  const pageContent = data.prismicMcmReviewForm;
  const page = pageContent.data || {};
  const pageContentFullportfoliopage = data.prismicFullportfoliopage;
  const pageFullportfoliopage = pageContentFullportfoliopage?.data || {};
  const firstFormVars = pageContent.data.body[0];
  const secondFormVars = pageContent.data.body[1];

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};

  function AfterSubmitModal() {
    return (
      <>
        <Modal
          animation={false}
          fullscreen
          show={showFinalSpinner}
          onHide={handleCloseFinalSpinner}
        >
          <Spinner size="sm" animation="border" variant="info" />
        </Modal>
      </>
    );
  }

  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
    setTimeout(() => {
      setShowFinalSpinner(false);
    }, 3000);
  };

  let user = '';
  let firebaseAuth = {};

  let firestore = {};
  if (isBrowser) {
    user = firebase.auth().currentUser;
    firebaseAuth = firebase.auth();

    firestore = firebase.firestore();
  }

  const mcm_name = currentMCMData?.full_name || 'Loading...';
  const mcmUID = user?.uid || 'Loading...';
  const mcmID = currentMCMData?.id || 'Loading...';

  //First Form
  const firstFormHandleUserDataOnSubmit = (values, actions) => {
    firstFormHandleSaveUserData(values);
    setFirstFormVisibility('d-none');
    setSecondFormVisibility('');
  };
  const firstFormHandleSaveUserData = values => {
    {
      currentReviewingProcess?.mixedProcessId === mcmUID + applicantID
        ? firstFormHandleUserDataUpdate(values)
        : handleUserDataAdd(values);
    }
  };
  const firstFormHandleUserDataUpdate = async values => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    alert('Your changes have been saved');
    setTimeout(() => {
      // navigate('/')
      // actions.setSubmitting(false)
    }, 2000);

    try {
      await firestore
        .collection('reviewingCollection')
        .doc(currentReviewingProcess.id)
        .update({
          is_this_the_first_review: values.is_this_the_first_review || '',
          advances_in_the_personal_development: values.advances_in_the_personal_development || '',
          advances_in_the_personal_development_comments:
            values.advances_in_the_personal_development_comments || '',
          validating_the_applicant_academic_position:
            values.validating_the_applicant_academic_position || '',
          validating_the_applicant_academic_position_comments:
            values.validating_the_applicant_academic_position_comments || '',
          validating_the_knowledge_domain: values.validating_the_knowledge_domain || '',
          validating_the_knowledge_domain_comments:
            values.validating_the_knowledge_domain_comments || '',
          knowledge_domain_relevant_for_the_profession:
            values.knowledge_domain_relevant_for_the_profession || '',
          knowledge_domain_relevant_for_the_profession_comments:
            values.knowledge_domain_relevant_for_the_profession_comments || '',
          evidence_of_the_highest_academic_degree_provided:
            values.evidence_of_the_highest_academic_degree_provided || '',
          evidence_of_the_highest_academic_degree_provided_comments:
            values.evidence_of_the_highest_academic_degree_provided_comments || '',
          evidencing_the_overall_pedagogical_experience:
            values.evidencing_the_overall_pedagogical_experience || '',
          evidencing_the_overall_pedagogical_experience_comments:
            values.evidencing_the_overall_pedagogical_experience_comments || '',
          are_the_courses_lectured: values.are_the_courses_lectured || '',
          are_the_courses_lectured_comments: values.are_the_courses_lectured_comments || '',
          is_the_membership_in_pedagogical_committee_relevant:
            values.is_the_membership_in_pedagogical_committee_relevant || '',
          is_the_membership_in_pedagogical_committee_relevant_comments:
            values.is_the_membership_in_pedagogical_committee_relevant_comments || '',
          are_the_trainings_relevant: values.are_the_trainings_relevant || '',
          are_the_trainings_relevant_comments: values.are_the_trainings_relevant_comments || '',
          are_the_conferences_relevant: values.are_the_conferences_relevant || '',
          are_the_conferences_relevant_comments: values.are_the_conferences_relevant_comments || '',
          are_the_pedagogical_experience_relevant:
            values.are_the_pedagogical_experience_relevant || '',
          are_the_pedagogical_experience_relevant_comments:
            values.are_the_pedagogical_experience_relevant_comments || '',
          are_the_internship_experience_relevant:
            values.are_the_internship_experience_relevant || '',
          are_the_internship_experience_relevant_comments:
            values.are_the_internship_experience_relevant_comments || '',
          are_the_membership_in_associations_relevant:
            values.are_the_membership_in_associations_relevant || '',
          are_the_membership_in_associations_relevant_comments:
            values.are_the_membership_in_associations_relevant_comments || '',
          is_the_research_evidenced_is_relevant: values.is_the_research_evidenced_is_relevant || '',
          is_the_research_evidenced_is_relevant_comments:
            values.is_the_research_evidenced_is_relevant_comments || '',
          are_the_awards_relevant: values.are_the_awards_relevant || '',
          are_the_awards_relevant_comments: values.are_the_awards_relevant_comments || '',
          is_the_motivation_relevant: values.is_the_motivation_relevant || '',
          is_the_motivation_relevant_comments: values.is_the_motivation_relevant_comments || '',
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserDataAdd = async values => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    alert('Your changes have been saved');

    try {
      await firestore
        .collection('reviewingCollection')
        .add({
          mixedProcessId: mcmUID + applicantID || '',
          mcm_name: values.mcm_name || '',
          mcmID: mcmID || '',
          applicantID: values.applicantID || '',
          is_this_the_first_review: values.is_this_the_first_review || '',
          advances_in_the_personal_development: values.advances_in_the_personal_development || '',
          advances_in_the_personal_development_comments:
            values.advances_in_the_personal_development_comments || '',
          validating_the_applicant_academic_position:
            values.validating_the_applicant_academic_position || '',
          validating_the_applicant_academic_position_comments:
            values.validating_the_applicant_academic_position_comments || '',
          validating_the_knowledge_domain: values.validating_the_knowledge_domain || '',
          validating_the_knowledge_domain_comments:
            values.validating_the_knowledge_domain_comments || '',
          knowledge_domain_relevant_for_the_profession:
            values.knowledge_domain_relevant_for_the_profession || '',
          knowledge_domain_relevant_for_the_profession_comments:
            values.knowledge_domain_relevant_for_the_profession_comments || '',
          evidence_of_the_highest_academic_degree_provided:
            values.evidence_of_the_highest_academic_degree_provided || '',
          evidence_of_the_highest_academic_degree_provided_comments:
            values.evidence_of_the_highest_academic_degree_provided_comments || '',
          evidencing_the_overall_pedagogical_experience:
            values.evidencing_the_overall_pedagogical_experience || '',
          evidencing_the_overall_pedagogical_experience_comments:
            values.evidencing_the_overall_pedagogical_experience_comments || '',
          are_the_courses_lectured: values.are_the_courses_lectured || '',
          are_the_courses_lectured_comments: values.are_the_courses_lectured_comments || '',
          is_the_membership_in_pedagogical_committee_relevant:
            values.is_the_membership_in_pedagogical_committee_relevant || '',
          is_the_membership_in_pedagogical_committee_relevant_comments:
            values.is_the_membership_in_pedagogical_committee_relevant_comments || '',
          are_the_trainings_relevant: values.are_the_trainings_relevant || '',
          are_the_trainings_relevant_comments: values.are_the_trainings_relevant_comments || '',
          are_the_conferences_relevant: values.are_the_conferences_relevant || '',
          are_the_conferences_relevant_comments: values.are_the_conferences_relevant_comments || '',
          are_the_pedagogical_experience_relevant:
            values.are_the_pedagogical_experience_relevant || '',
          are_the_pedagogical_experience_relevant_comments:
            values.are_the_pedagogical_experience_relevant_comments || '',
          are_the_internship_experience_relevant:
            values.are_the_internship_experience_relevant || '',
          are_the_internship_experience_relevant_comments:
            values.are_the_internship_experience_relevant_comments || '',
          are_the_membership_in_associations_relevant:
            values.are_the_membership_in_associations_relevant || '',
          are_the_membership_in_associations_relevant_comments:
            values.are_the_membership_in_associations_relevant_comments || '',
          is_the_research_evidenced_is_relevant: values.is_the_research_evidenced_is_relevant || '',
          is_the_research_evidenced_is_relevant_comments:
            values.is_the_research_evidenced_is_relevant_comments || '',
          are_the_awards_relevant: values.are_the_awards_relevant || '',
          are_the_awards_relevant_comments: values.are_the_awards_relevant_comments || '',
          is_the_motivation_relevant: values.is_the_motivation_relevant || '',
          is_the_motivation_relevant_comments: values.is_the_motivation_relevant_comments || '',
          //
          innovations_in_engineering_pedagogy_evidenced: '',
          innovations_in_engineering_pedagogy_strengths: '',
          innovations_in_engineering_pedagogy_suggestions: '',
          time_management_evidenced: '',
          time_management_evidenced_strengths: '',
          time_management_evidenced_suggestions: '',
          effective_interaction_evidenced: '',
          effective_interaction_evidenced_strengths: '',
          effective_interaction_evidenced_suggestions: '',
          enhancement_of_learning_interactivity: '',
          enhancement_of_learning_interactivity_strengths: '',
          enhancement_of_learning_interactivity_suggestions: '',
          systems_analysis_in_education: '',
          systems_analysis_in_education_strengths: '',
          systems_analysis_in_education_suggestions: '',
          pedagogical_psychology_and_communication: '',
          pedagogical_psychology_and_communication_strengths: '',
          pedagogical_psychology_and_communication_suggestions: '',
          interaction_with_stakeholders: '',
          interaction_with_stakeholders_strengths: '',
          interaction_with_stakeholders_suggestions: '',
          sustainable_development: '',
          sustainable_development_strengths: '',
          sustainable_development_suggestions: '',
          digital_education: '',
          digital_education_strengths: '',
          digital_education_suggestions: '',
          problem_based_project_based_and_practice_oriented_learning: '',
          problem_based_project_based_and_practice_oriented_learning_strengths: '',
          problem_based_project_based_and_practice_oriented_learning_suggestions: '',
          learning_outcomes_assessment: '',
          learning_outcomes_assessment_strengths: '',
          learning_outcomes_assessment_suggestions: '',
          course_design: '',
          course_design_strengths: '',
          course_design_suggestions: '',
          engineering_innovation_process: '',
          engineering_innovation_process_strengths: '',
          engineering_innovation_process_suggestions: '',
          lifelong_learning: '',
          lifelong_learning_strengths: '',
          lifelong_learning_suggestions: '',
          facebook_link: values.facebook_link || '',
          linkedin_link: values.linkedin_link || '',
          instagram_link: values.instagram_link || '',
          twitter_link: values.twitter_link || '',
          personal_website_link: values.personal_website_link || '',
          comments_to_the_monitoring_committee_chair: '',
        })
        .then(ref => {
          //https://firebase.google.com/docs/auth/web/manage-users#update_a_users_profile
          // console.log(`Added document with ID:`, ref.id)
        });
    } catch (error) {
      console.log(error);
    }
  };

  const firstFormInitialValues = {
    mcm_name,
    applicantID,
    is_this_the_first_review: currentReviewingProcess?.is_this_the_first_review || '',
    advances_in_the_personal_development:
      currentReviewingProcess?.advances_in_the_personal_development || '',
    advances_in_the_personal_development_comments:
      currentReviewingProcess?.advances_in_the_personal_development_comments || '',
    validating_the_applicant_academic_position:
      currentReviewingProcess?.validating_the_applicant_academic_position || '',
    validating_the_applicant_academic_position_comments:
      currentReviewingProcess?.validating_the_applicant_academic_position_comments || '',
    validating_the_knowledge_domain: currentReviewingProcess?.validating_the_knowledge_domain || '',
    validating_the_knowledge_domain_comments:
      currentReviewingProcess?.validating_the_knowledge_domain_comments || '',
    knowledge_domain_relevant_for_the_profession:
      currentReviewingProcess?.knowledge_domain_relevant_for_the_profession || '',
    knowledge_domain_relevant_for_the_profession_comments:
      currentReviewingProcess?.knowledge_domain_relevant_for_the_profession_comments || '',
    evidence_of_the_highest_academic_degree_provided:
      currentReviewingProcess?.evidence_of_the_highest_academic_degree_provided || '',
    evidence_of_the_highest_academic_degree_provided_comments:
      currentReviewingProcess?.evidence_of_the_highest_academic_degree_provided_comments || '',
    evidencing_the_overall_pedagogical_experience:
      currentReviewingProcess?.evidencing_the_overall_pedagogical_experience || '',
    evidencing_the_overall_pedagogical_experience_comments:
      currentReviewingProcess?.evidencing_the_overall_pedagogical_experience_comments || '',
    are_the_courses_lectured: currentReviewingProcess?.are_the_courses_lectured || '',
    are_the_courses_lectured_comments:
      currentReviewingProcess?.are_the_courses_lectured_comments || '',
    is_the_membership_in_pedagogical_committee_relevant:
      currentReviewingProcess?.is_the_membership_in_pedagogical_committee_relevant || '',
    is_the_membership_in_pedagogical_committee_relevant_comments:
      currentReviewingProcess?.is_the_membership_in_pedagogical_committee_relevant_comments || '',
    are_the_trainings_relevant: currentReviewingProcess?.are_the_trainings_relevant || '',
    are_the_trainings_relevant_comments:
      currentReviewingProcess?.are_the_trainings_relevant_comments || '',
    are_the_conferences_relevant: currentReviewingProcess?.are_the_conferences_relevant || '',
    are_the_conferences_relevant_comments:
      currentReviewingProcess?.are_the_conferences_relevant_comments || '',
    are_the_pedagogical_experience_relevant:
      currentReviewingProcess?.are_the_pedagogical_experience_relevant || '',
    are_the_pedagogical_experience_relevant_comments:
      currentReviewingProcess?.are_the_pedagogical_experience_relevant_comments || '',
    are_the_internship_experience_relevant:
      currentReviewingProcess?.are_the_internship_experience_relevant || '',
    are_the_internship_experience_relevant_comments:
      currentReviewingProcess?.are_the_internship_experience_relevant_comments || '',
    are_the_membership_in_associations_relevant:
      currentReviewingProcess?.are_the_membership_in_associations_relevant || '',
    are_the_membership_in_associations_relevant_comments:
      currentReviewingProcess?.are_the_membership_in_associations_relevant_comments || '',
    is_the_research_evidenced_is_relevant:
      currentReviewingProcess?.is_the_research_evidenced_is_relevant || '',
    is_the_research_evidenced_is_relevant_comments:
      currentReviewingProcess?.is_the_research_evidenced_is_relevant_comments || '',
    are_the_awards_relevant: currentReviewingProcess?.are_the_awards_relevant || '',
    are_the_awards_relevant_comments:
      currentReviewingProcess?.are_the_awards_relevant_comments || '',
    is_the_motivation_relevant: currentReviewingProcess?.is_the_motivation_relevant || '',
    is_the_motivation_relevant_comments:
      currentReviewingProcess?.is_the_motivation_relevant_comments || '',
  };

  const firstFormValidationSchema = Yup.object().shape({
    mcm_name: Yup.string().required('Required'),
    applicantID: Yup.string().required('Required'),
    is_this_the_first_review: Yup.string().required('Required'),
    advances_in_the_personal_development: Yup.string(),
    advances_in_the_personal_development_comments: Yup.string(),
    validating_the_applicant_academic_position: Yup.string().required('Required'),
    validating_the_applicant_academic_position_comments: Yup.string(),
    validating_the_knowledge_domain: Yup.string().required('Required'),
    validating_the_knowledge_domain_comments: Yup.string(),
    knowledge_domain_relevant_for_the_profession: Yup.string().required('Required'),
    knowledge_domain_relevant_for_the_profession_comments: Yup.string(),
    evidence_of_the_highest_academic_degree_provided: Yup.string().required('Required'),
    evidence_of_the_highest_academic_degree_provided_comments: Yup.string(),
    evidencing_the_overall_pedagogical_experience: Yup.string().required('Required'),
    evidencing_the_overall_pedagogical_experience_comments: Yup.string(),
    are_the_courses_lectured: Yup.string().required('Required'),
    are_the_courses_lectured_comments: Yup.string(),
    is_the_membership_in_pedagogical_committee_relevant: Yup.string().required('Required'),
    is_the_membership_in_pedagogical_committee_relevant_comments: Yup.string(),
    are_the_trainings_relevant: Yup.string().required('Required'),
    are_the_trainings_relevant_comments: Yup.string(),
    are_the_conferences_relevant: Yup.string().required('Required'),
    are_the_conferences_relevant_comments: Yup.string(),
    are_the_pedagogical_experience_relevant: Yup.string().required('Required'),
    are_the_pedagogical_experience_relevant_comments: Yup.string(),
    are_the_internship_experience_relevant: Yup.string().required('Required'),
    are_the_internship_experience_relevant_comments: Yup.string(),
    are_the_membership_in_associations_relevant: Yup.string().required('Required'),
    are_the_membership_in_associations_relevant_comments: Yup.string(),
    is_the_research_evidenced_is_relevant: Yup.string().required('Required'),
    is_the_research_evidenced_is_relevant_comments: Yup.string(),
    are_the_awards_relevant: Yup.string().required('Required'),
    are_the_awards_relevant_comments: Yup.string(),
    is_the_motivation_relevant: Yup.string().required('Required'),
    is_the_motivation_relevant_comments: Yup.string(),
  });

  //Second Form
  const secondFormInitialValues = currentReviewingProcess || {};

  const secondFormHandleUserDataUpdate = async values => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    alert('Your changes have been saved');
    setTimeout(() => {
      // navigate('/')
      // actions.setSubmitting(false)
    }, 2000);

    try {
      await firestore
        .collection('reviewingCollection')
        .doc(currentReviewingProcess?.id)
        .update({
          innovations_in_engineering_pedagogy_evidenced:
            values.innovations_in_engineering_pedagogy_evidenced || '',
          innovations_in_engineering_pedagogy_strengths:
            values.innovations_in_engineering_pedagogy_strengths || '',
          innovations_in_engineering_pedagogy_suggestions:
            values.innovations_in_engineering_pedagogy_suggestions || '',
          time_management_evidenced: values.time_management_evidenced || '',
          time_management_evidenced_strengths: values.time_management_evidenced_strengths || '',
          time_management_evidenced_suggestions: values.time_management_evidenced_suggestions || '',
          effective_interaction_evidenced: values.effective_interaction_evidenced || '',
          effective_interaction_evidenced_strengths:
            values.effective_interaction_evidenced_strengths || '',
          effective_interaction_evidenced_suggestions:
            values.effective_interaction_evidenced_suggestions || '',
          enhancement_of_learning_interactivity: values.enhancement_of_learning_interactivity || '',
          enhancement_of_learning_interactivity_strengths:
            values.enhancement_of_learning_interactivity_strengths || '',
          enhancement_of_learning_interactivity_suggestions:
            values.enhancement_of_learning_interactivity_suggestions || '',
          systems_analysis_in_education: values.systems_analysis_in_education || '',
          systems_analysis_in_education_strengths:
            values.systems_analysis_in_education_strengths || '',
          systems_analysis_in_education_suggestions:
            values.systems_analysis_in_education_suggestions || '',
          pedagogical_psychology_and_communication:
            values.pedagogical_psychology_and_communication || '',
          pedagogical_psychology_and_communication_strengths:
            values.pedagogical_psychology_and_communication_strengths || '',
          pedagogical_psychology_and_communication_suggestions:
            values.pedagogical_psychology_and_communication_suggestions || '',
          interaction_with_stakeholders: values.interaction_with_stakeholders || '',
          interaction_with_stakeholders_strengths:
            values.interaction_with_stakeholders_strengths || '',
          interaction_with_stakeholders_suggestions:
            values.interaction_with_stakeholders_suggestions || '',
          sustainable_development: values.sustainable_development || '',
          sustainable_development_strengths: values.sustainable_development_strengths || '',
          sustainable_development_suggestions: values.sustainable_development_suggestions || '',
          digital_education: values.digital_education || '',
          digital_education_strengths: values.digital_education_strengths || '',
          digital_education_suggestions: values.digital_education_suggestions || '',
          problem_based_project_based_and_practice_oriented_learning:
            values.problem_based_project_based_and_practice_oriented_learning || '',
          problem_based_project_based_and_practice_oriented_learning_strengths:
            values.problem_based_project_based_and_practice_oriented_learning_strengths || '',
          problem_based_project_based_and_practice_oriented_learning_suggestions:
            values.problem_based_project_based_and_practice_oriented_learning_suggestions || '',
          learning_outcomes_assessment: values.learning_outcomes_assessment || '',
          learning_outcomes_assessment_strengths:
            values.learning_outcomes_assessment_strengths || '',
          learning_outcomes_assessment_suggestions:
            values.learning_outcomes_assessment_suggestions || '',
          course_design: values.course_design || '',
          course_design_strengths: values.course_design_strengths || '',
          course_design_suggestions: values.course_design_suggestions || '',
          engineering_innovation_process: values.engineering_innovation_process || '',
          engineering_innovation_process_strengths:
            values.engineering_innovation_process_strengths || '',
          engineering_innovation_process_suggestions:
            values.engineering_innovation_process_suggestions || '',
          lifelong_learning: values.lifelong_learning || '',
          lifelong_learning_strengths: values.lifelong_learning_strengths || '',
          lifelong_learning_suggestions: values.lifelong_learning_suggestions || '',
          facebook_link: values.facebook_link || '',
          linkedin_link: values.linkedin_link || '',
          instagram_link: values.instagram_link || '',
          twitter_link: values.twitter_link || '',
          personal_website_link: values.personal_website_link || '',
          // has_completed_pdps: values.has_completed_pdps || '',
          // has_completed_pdps_comments: values.has_completed_pdps_comments || '',
          comments_to_the_monitoring_committee_chair:
            values.comments_to_the_monitoring_committee_chair || '',
        });
    } catch (error) {
      console.log(error);
    }
  };

  const isReviewSubmited = async () => {
    if (userUnderReview?.mcm1 === currentMCMData.id) {
      try {
        await firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm1ReviewSubmited: true,
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (userUnderReview?.mcm2 === currentMCMData.id) {
      try {
        await firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm2ReviewSubmited: true,
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (userUnderReview?.mcm3 === currentMCMData.id) {
      try {
        await firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm3ReviewSubmited: true,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  const cleanUpMCMID = () => {
    if (userUnderReview?.mcm1 === currentMCMData.id) {
      try {
        firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm1: 'submitted',
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (userUnderReview?.mcm2 === currentMCMData.id) {
      try {
        firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm2: 'submitted',
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (userUnderReview?.mcm3 === currentMCMData.id) {
      try {
        firestore.collection('usersCollection').doc(userUnderReview.id).update({
          mcm3: 'submitted',
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const secondFormValidationSchema = Yup.object().shape({
    innovations_in_engineering_pedagogy_evidenced: Yup.string().required('Required'),
    innovations_in_engineering_pedagogy_strengths: Yup.string(),
    innovations_in_engineering_pedagogy_suggestions: Yup.string(),
    time_management_evidenced: Yup.string().required('Required'),
    time_management_evidenced_strengths: Yup.string(),
    time_management_evidenced_suggestions: Yup.string(),
    effective_interaction_evidenced: Yup.string().required('Required'),
    effective_interaction_evidenced_strengths: Yup.string(),
    effective_interaction_evidenced_suggestions: Yup.string(),
    enhancement_of_learning_interactivity: Yup.string().required('Required'),
    enhancement_of_learning_interactivity_strengths: Yup.string(),
    enhancement_of_learning_interactivity_suggestions: Yup.string(),
    systems_analysis_in_education: Yup.string().required('Required'),
    systems_analysis_in_education_strengths: Yup.string(),
    systems_analysis_in_education_suggestions: Yup.string(),
    pedagogical_psychology_and_communication: Yup.string().required('Required'),
    pedagogical_psychology_and_communication_strengths: Yup.string(),
    pedagogical_psychology_and_communication_suggestions: Yup.string(),
    interaction_with_stakeholders: Yup.string().required('Required'),
    interaction_with_stakeholders_strengths: Yup.string(),
    interaction_with_stakeholders_suggestions: Yup.string(),
    sustainable_development: Yup.string().required('Required'),
    sustainable_development_strengths: Yup.string(),
    sustainable_development_suggestions: Yup.string(),
    digital_education: Yup.string().required('Required'),
    digital_education_strengths: Yup.string(),
    digital_education_suggestions: Yup.string(),
    problem_based_project_based_and_practice_oriented_learning: Yup.string().required('Required'),
    problem_based_project_based_and_practice_oriented_learning_strengths: Yup.string(),
    problem_based_project_based_and_practice_oriented_learning_suggestions: Yup.string(),
    learning_outcomes_assessment: Yup.string().required('Required'),
    learning_outcomes_assessment_strengths: Yup.string(),
    learning_outcomes_assessment_suggestions: Yup.string(),
    course_design: Yup.string().required('Required'),
    course_design_strengths: Yup.string(),
    course_design_suggestions: Yup.string(),
    engineering_innovation_process: Yup.string().required('Required'),
    engineering_innovation_process_strengths: Yup.string(),
    engineering_innovation_process_suggestions: Yup.string(),
    lifelong_learning: Yup.string().required('Required'),
    lifelong_learning_strengths: Yup.string(),
    lifelong_learning_suggestions: Yup.string(),
    facebook_link: Yup.string(),
    linkedin_link: Yup.string(),
    instagram_link: Yup.string(),
    twitter_link: Yup.string(),
    personal_website_link: Yup.string(),
    // has_completed_pdps: Yup.string(),
    // has_completed_pdps_comments: Yup.string(),
    comments_to_the_monitoring_committee_chair: Yup.string(),
  });

  const secondFormHandleUserDataOnSubmit = (values, actions) => {
    // cleanUpMCMID()
    secondFormHandleUserDataUpdate(values);
    isReviewSubmited();
    setFirstFormVisibility('d-none');
    setSecondFormVisibility('d-none');
    setFullPortfolioVisibility('d-none');
    setTitleVisibility('d-none');
    setFinalInfo('');
  };

  const SecondFormTitle = () => {
    return (
      <div>
        <h3 className="text-center mb-4">
          {page.enter_register_review ? page.enter_register_review : 'No Title'}
        </h3>
        <p>
          Please, provide your expert opinion if the Applicant justified and evidenced achievement
          of the competences below:
        </p>
      </div>
    );
  };
  const FirstFormTitle = () => {
    return (
      <h3 className="text-center mb-4">
        {page.applicants_portfolio_review ? page.applicants_portfolio_review : 'No Title'}
      </h3>
    );
  };

  const FinalInfoPopUp = ({ visible }) => {
    return (
      <Container className={`container-success ${visible}`}>
        <h1 className="page-title pt-4">
          <i className="fa fa-check-circle text-success"></i>
          Thank you!
        </h1>
        <div className="success-message-wrapper">
          <div>
            The review form has been successfully submitted. We appreciate your time, dedication and
            contribution to the improvement of engineering educator profession.
          </div>
          <div className="buttons-wrapper">
            <Link className="success-btns" to={`${localLinkResolver}/`}>
              <Button variant="outline-success">Home Page</Button>
            </Link>
            <Link className="success-btns" to={`${localLinkResolver}/personal-page`}>
              <Button variant="outline-success">Personal Page</Button>
            </Link>
          </div>
        </div>
      </Container>
    );
  };

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page?.mcm_form_title ? page.mcm_form_title.text : 'No Title'} lang={lang} />
      <Container className="personal-portfolio">
        <h1 className={`page-title ${titleVisibility}`}>
          {page?.mcm_form_title ? page.mcm_form_title.text : 'No Title'}
        </h1>
        <FinalInfoPopUp visible={finalInfo} />
        <div className="form-review-wrapper">
          <div className="form-review-left">
            {/* //First Form */}
            <Formik
              enableReinitialize
              initialValues={firstFormInitialValues}
              isSubmitting={true}
              validationSchema={firstFormValidationSchema}
              onSubmit={firstFormHandleUserDataOnSubmit}
            >
              {props => (
                <PersonalDataForm
                  className={firstFormVisibility}
                  cancelButtonLabel={'Save and go back'}
                  FirstFormTitle={FirstFormTitle}
                  saveButtonLabel={'Save'}
                  submitButtonLabel={'Save and Continue'}
                  mainFormVars={firstFormVars.items}
                  cancelAction={async () => {
                    handleShowFinalSpinner();
                    firstFormHandleSaveUserData(props.values);
                    navigate('/personal-page');
                  }}
                  saveForm={() => {
                    firstFormHandleSaveUserData(props.values);
                  }}
                  {...props}
                />
              )}
            </Formik>

            {/* Second Form */}

            <Formik
              enableReinitialize
              initialValues={secondFormInitialValues}
              isSubmitting={true}
              validationSchema={secondFormValidationSchema}
              onSubmit={secondFormHandleUserDataOnSubmit}
            >
              {props => (
                <PersonalDataForm
                  withTooltips={true} //conditional tooltip rendering from placeholder on main questions
                  className={secondFormVisibility}
                  SecondFormTitle={SecondFormTitle}
                  cancelButtonLabel={'Save and go back'}
                  saveButtonLabel={'Save'}
                  submitButtonLabel={'Save and Submit'}
                  mainFormVars={secondFormVars.items}
                  firstSubTitle={page.applicants_portfolio_review}
                  secondSubTitle={page.enter_register_review}
                  cancelAction={() => {
                    handleShowFinalSpinner();
                    setFirstFormVisibility('');
                    setSecondFormVisibility('d-none');
                    secondFormHandleUserDataUpdate(props.values);
                    // navigate('/login')
                  }}
                  saveForm={() => {
                    secondFormHandleUserDataUpdate(props.values);
                  }}
                  {...props}
                />
              )}
            </Formik>
          </div>
          <div className={`form-review-right ${fullPortfolioVisibility}`}>
            <div className="position-sticky top-0 bg-white">
              <h2 className="mb-3 pt-2 mx-auto d-flex justify-content-center text-center">
                Full Portfolio of the candidate
              </h2>
              <hr />
            </div>
            <ul className="fullPersonalPortfolio ml-1 px-4">
              {pageFullportfoliopage?.full_portfolio_labels?.map((field, key) => {
                const fieldValueArray = formFieldEntries?.filter(
                  userField => userField[0] === field.variable_name,
                );
                let fieldValue = 'Not provided ...';
                let keyE = 'Not provided ...';

                for (const [key, value] of Object.entries(fieldValueArray)) {
                  keyE = key;
                  fieldValue = value[1];
                }

                if (field.variable_name === 'portfolio_file') {
                  return (
                    <li key={key}>
                      {field.field_label} :{' '}
                      <a
                        className="text-white bg-success p-2 rounded"
                        href={fieldValue}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Document
                      </a>
                    </li>
                  );
                }
                if (field.variable_name === 'requestID') {
                  return;
                }

                return (
                  <li key={key} className="mb-1">
                    {field.field_label} :{' '}
                    <span className="value rounded-3 text-break mt-1 p-0">{fieldValue}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Container>
      <AfterSubmitModal />
    </Layout>
  );
};

export const query = graphql`
  query McmReviewFormPageQuery($id: String, $lang: String) {
    prismicMcmReviewForm(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        applicants_portfolio_review
        enter_register_review
        mcm_form_title {
          text
        }
        body {
          ... on PrismicMcmReviewFormDataBodyForm {
            items {
              description
              field_type
              form_type
              name
              placeholder
              required
              radio_options
              title
            }
          }
        }
      }
    }
    prismicFullportfoliopage(lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        full_portfolio {
          text
        }
        full_portfolio_labels {
          key
          variable_name
          field_label
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(McmReviewForm, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
